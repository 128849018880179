<template>
  <overlay-scrollbars class="page page-offers shapes-bg" ref="os">
    <div class="box-container">
      <div class="transparent-box box_b-info">
        <div class="content">
          <div class="page-category-tab">Parnership</div> <br>
          <div class="section-title-wrap">
            <h2 class="page-details__title section-title">
              <span class="bc-first">Clients</span>
            </h2>
          </div>
          <div class="selected-locations">
            <div class="location" v-for="location in selectedLocations">
              <span>{{ location.name }}</span>
              <div class="location__close" @click="removeSelectedLocation(location.id)">
                &#10006;
              </div>
            </div>
          </div>
          <!-- <div class="row customer-search-name-wrapper">
            <input v-model="customerName" name="company_full_name" type="text" placeholder="Enter cutomer name"
              class="form-control customer-search-name">
            <div v-on:click.stop.prevent="downloadExcel" class="btn excel-btn">Download to Excel</div>
          </div> -->

          <div class="row columns-search">
            <div class="input-wrapper">
              <SearchIcon fill="#5E5E5E" />
              <input type="text" v-model="filter.name" placeholder="Customer name" @input="filterByColumn('name')">
            </div>
            <div class="input-wrapper">
              <SearchIcon fill="#5E5E5E" />
              <MultiSelect v-model="selectedLocations" :options="countries" placeholder="UBO Residential"
                optionLabel="name" :selectionLimit="3" :showToggleAll="false" class="columns-search__multiselect"
                @change="filterByColumn('locations')" />
            </div>
            <div class="input-wrapper">
              <SearchIcon fill="#5E5E5E" />
              <Calendar type="text" v-model="filter.date_join" placeholder="Date Join"
                @input="filterByColumn('date-join')" dateFormat="dd/mm/yy" />
            </div>
          </div>

          <!-- <div class="block">
            <Table @editItem="editItem" @deleteItem="deleteItem" :key="customersTableKey" :options="customersTableData"
              @change-page="getCustomers" @scroll-top="toElTop" />
          </div> -->

          <div class="offers-table main-table clients-table">
            <DataTable :value="customers" dataKey="id" tableClass="page-clients__table" :loading="loading">
              <Column field="id" header="Cust. ID"></Column>
              <Column field="created_at_text" header="Date Join" sortable></Column>
              <Column field="name" header="Customer Name" sortable></Column>
              <Column field="manager_name" header="Manager" sortable></Column>
              <Column field="general_compliance" header="Compliance" sortable></Column>
              <Column field="customer_type_name" header="Customer Type" sortable></Column>
              <Column field="business_settings.ubo_residential_name" header="UBO Residential" sortable></Column>
              <Column field="request_changed_date" header="Last Request" sortable></Column>
              <Column>
                <template #body="{ data }">
                  <div class="btns">
                    <Button @click="editItem(data)" class="table-btn">
                      <EditIcon fill="#000" />
                    </Button>
                    <div v-if="$store.state.user.is_super_admin">
                      <Tmblr 
                        :active="data.is_manager_can" 
                        customClass="dark"
                        @changeState="toggleProperty(data.id, 'is_manager_can')" />
                    </div>
                    <Button @click="deleteItem(data)" class="table-btn" style="margin-left: 20px">
                      <TableRemoveItemIcon />
                    </Button>
                  </div>
                </template>
              </Column>
            </DataTable>
            <pagination :data="customersPagination" @pagination-change-page="getCustomers">
              <span slot="prev-nav">&lt;</span>
              <span slot="next-nav">&gt;</span>
            </pagination>
          </div>

        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import ArrowsIcon from "@/components/icons/ArrowsIcon.vue";
import DownloadIcon from "@/components/icons/DownloadIcon.vue";
import SearchIcon from "@/components/icons/SearchIcon.vue";
import TableRemoveItemIcon from '@/components/icons/TableRemoveItemIcon.vue';
import EditIcon from "@/components/icons/EditIcon.vue";
import Tmblr from '@/components/elem/Tmblr';

export default {
  components: {
    ArrowsIcon,
    DownloadIcon,
    SearchIcon,
    TableRemoveItemIcon,
    EditIcon,
    Tmblr,
  },
  data: () => ({
    customers: [],
    customersPagination: {},
    filter: {
      name: '',
      date_join: ''
    },
    selectedLocations: [],
    loading: false,
    // customersTableKey: 0,
    // customersInfo: {
    //   total: '',
    //   fintechUsersCount: '',
    //   standartUsersCount: '',
    //   newUsersCount: '',
    //   kybRateCount: '',
    // },
    // customerName: '',
    // customerNameDelayTimer: null,
    // customersTableData: {
    //   colsWidth: ['9%', '9%', '9%', '9%', '9%', '9%', '9%', '9%', '9%', '9%', '9%'],
    //   header: [
    //     { ttl: 'Last Request', info: null },
    //     { ttl: 'Balance', info: null },
    //     { ttl: 'Discount code', info: null },
    //     { ttl: '', info: null },
    //   ],
    //   body: [],
    //   paginationData: null,
    // },
  }),
  created() {
    this.getCustomers()
    this.setCountries()
  },
  computed: {
    ...mapState({
      countries: state => state.countries,
    }),
  },
  methods: {
    ...mapActions({
      setCountries: 'setCountries',
    }),
    toggleProperty(id, property){
      if(id){
        let urlPart = '';
        
        if(property == 'is_manager_can'){
          urlPart = 'toggle-can';
        }
        
        let that = this
        
        axios.post('admin/customers/' + id + '/' + urlPart)
        .then(function (response) {
          that.getCustomers();
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    toElTop(el) {
      this.$refs.os.osInstance().scroll({ el: el, margin: 10 }, 1500)
    },
    async getCustomers(page = 1) {
      let url = 'admin/customers?page=' + page;
      // if (this.customerName.trim().length > 0) {
      //   url = url + '&customer_name=' + this.customerName
      // }
      this.loading = true;

      await axios.get(url)
        .then(res => {
          this.customersPagination = res.data
          // this.customersTableData.paginationData = response.data
          // this.customersTableData.body = []
          this.customers = [];

          res.data.data.forEach(el => {
            this.customers.push(el)
            // const isNewHtml = (el.payment_settings && el.payment_settings.payment_request_settings_changed) ? '<span class="badge  new-request-badge">New</span>' : ''
            // const requestHtml = (el.request_changed_date) ? el.request_changed_date + isNewHtml : ''
            // let balanceItem = (el.balance.trim().length !== 0 && el.balance) ? this.number_format(el.balance, 2, '.', ' ') : ''
            // if (el.balance_currency) {
            //   balanceItem = balanceItem + ' ' + el.balance_currency.code;
            // }
            // let name = el.full_name
            // const kybRate = (parseInt(el.is_kyb_rate_manual) == 1) ? el.kyb_rate_manual : el.kyb_rate
            // if (!name && typeof el.company_full_name !== 'undefined') {
            //   name = el.company_full_name
            // }
            // this.customersTableData.body.push([
            //   el.serial_number,
            //   el.created_at_text,
            //   name,
            //   { type: 'rating', rating: el.rating, rating_color: el.rating_color },
            //   kybRate.toString(),
            //   el.customer_type_name,
            //   el.location,
            //   { type: 'request-change', html: requestHtml },
            //   balanceItem,
            //   el.discount_code,
            //   { type: 'edit-delete-actions', id: el.id }
            // ])
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })

      this.loading = false;
    },
    // getCustomersInfo() {
    //   const that = this
    //   axios.get('admin/customers/info')
    //     .then(function (response) {
    //       that.customersInfo = response.data
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     })
    // },
    deleteItem(obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          table: 'customers',
          type: 'customer',
          url: 'admin/customers/' + obj.id,
          successfullMessage: 'You successfully deleted customer',
        }
      })
    },
    editItem(obj) {
      this.$router.push({ name: "CustomerDetail", params: { id: obj.id } })
    },
    filterByColumn(key, page = 1) {
      let url = 'admin/customers?page=' + page;

      switch (key) {
        case 'name':
          url += '&filter[name]=' + this.filter.name;
          break;
        case 'date-join':
          const dateJoin = new Date(this.filter.date_join).toLocaleDateString('en-GB');
          url += '&filter[date_join]=' + dateJoin;
          break;
        case 'locations':
          this.selectedLocations.forEach(location => {
            url += '&filter[locations][]=' + location.id;
          })
      }

      axios.get(url)
        .then(res => {
          if (res.data.data) {
            this.customers = [];
            console.log(res.data)
            res.data.data.forEach(customer => {
              this.customers.push(customer);
            })
          }
        })
        .catch(err => console.dir(err))
    },
    removeSelectedLocation(id) {
      this.selectedLocations = this.selectedLocations.filter(item => item.id !== id);
    }
    // downloadExcel() {
    //   axios.post('admin/customers/create-excel-report')
    //     .then(function (response) {
    //       const url = response.data
    //       const link = document.createElement('a')
    //       link.href = url
    //       document.body.appendChild(link)
    //       link.click()
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     })
    // },
  },
  watch: {
    // customerName: function (customerName) {
    //   clearTimeout(this.customerNameDelayTimer);
    //   const that = this
    //   this.customerNameDelayTimer = setTimeout(function () {
    //     that.getCustomers()
    //   }, 300);
    // },
    '$store.state.deleteItemFromTableData': function (data) {
      this.getCustomers();
    }
  }
}
</script>

<style lang="scss" scoped>
.btns{
  display: flex;
}
.selected-locations {
  display: flex;
  margin-top: 20px;

  .location {
    display: flex;
    color: var(--c_black);
    border-radius: 8px;
    background-color: #ffffff47;
    padding: 10px 15px;
    margin-right: 10px;

    span {
      font-weight: bold;
    }

    &__close {
      margin-left: 10px;
    }
  }
}

.columns-search {
  display: flex;
  align-items: center;
  margin: 20px 0 25px 0;

  .input-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid var(--input_gray);
    border-radius: 6px;
    padding: 10px;
    margin-right: 10px;

    input {
      margin-left: 10px;
      border: none;
      background: transparent;

      &::placeholder {
        color: var(--input_gray);
      }

      &:focus {
        outline: none;
      }
    }
  }

  &__multiselect {
    padding: 0 !important;
    border: none;
  }
}


.transparent-box {
  width: 100%;
  border-radius: 14px;
  margin: 1rem 0;
}
</style>
