<template>
  <overlay-scrollbars class="page page-offer shapes-bg">
    <div class="section-title-wrap">
      <h2 class="page-details__title section-title">
        <span class="bc-first">Internal Deals</span>
      </h2>
    </div>
    <div class="top-btns-wrapper">
      <div @click="createLead" class="btn-gradient create-account-btn">Create</div>
      <div @click="documentTemplatesModal" class="btn-gradient create-account-btn">Document templates</div>
      <div @click="importantLinksModal" class="btn-gradient create-account-btn">Important links</div>
    </div>
    <div class="box box_selectedAcc box-bg-shapes">
      <div class="box-header">
        <div class="box-header__left">
          <div class="box-title">Internal Deals</div>
          <div>
            <input 
              class="search-input" 
              type="text" 
              placeholder="Type to search" 
              v-model="search_query"
              @change="getLeads"
              @keyup.enter="getLeads">
          </div>
          <div>
            <select name="search_country" class="search-input" v-model="search_country" @change="getLeads">
              <option :value="null">Select country</option>
              <option 
                v-for="(country, i) in countries" 
                :value="country.id"
                :key="i"
              >
                {{ country.name }}
              </option>
            </select>
          </div>
          <div>
            <select name="search_manager" class="search-input" v-model="search_manager" @change="getLeads">
              <option :value="null">Select manager</option>
              <option 
                v-for="(manager, i) in managers" 
                :value="manager.name"
                :key="i"
              >
                {{ manager.name }}
              </option>
            </select>
          </div>
          <div>
            <Tmblr 
              :active="showBreakedDeadline" 
              :text="'Show breaked deadline  (' + breakedDeadline + ')'" 
              @changeState="filterLeads" />
          </div>
        </div>
      </div>
      <div class="box-body">
        <div class="main-table offers-table">
          <DataTable :value="leads" dataKey="id" :loading="loading">
            <Column field="id" header="ID"></Column>
            <Column field="customer" header="Intrnal customer number"></Column>
            <Column field="client_number" header="Customer number"></Column>
            <Column field="customer_name" header="Name of customer">
              <template #body="{ data, field }">
                <a :href="data[field].link" v-if="data[field].link">
                  {{ data[field].text }}
                </a>
                <span v-else>
                  {{ data[field].text }}
                </span>
              </template>
            </Column>
            <Column field="joined_at" header="Joined at" sortable></Column>
            <Column field="status" header="Status">
              <template #body="{ data, field }">
                <div :style="'color:' + getStatusColor(data[field])">
                  {{ data[field] }}
                </div>
              </template>
            </Column>
            <Column field="connect_links" header="Connect links">
              <template #body="{ data, field }">
                <a :href="item.link" v-for="(item, i) in data[field]">
                  {{ item.text }}
                </a>
              </template>
            </Column>
            <Column field="customer_type" header="Customer type"></Column>
            <Column field="country" header="Location"></Column>
            <Column field="last_request" header="Last Request">
              <template #body="{ data, field }">
                <div class="show-more" :class="{'hidden': show_more != data['id']}">
                  {{ data[field] }}
                </div>
                <span class="fake-link" @click="showMore(data['id'])"> 
                  <span v-if="show_more != data['id']">
                    show more
                  </span>
                  <span v-else>
                    show less
                  </span>
                </span>
              </template>
            </Column>
            <Column field="balance" header="Balance"></Column>
            <Column field="manager_name" header="Manager name"></Column>
            <Column field="agent_name" header="Agent name & fee"></Column>
            <Column field="updated_at" header="Date of last update"></Column>
            <Column field="deadline_at" header="Deadline at">
              <template #body="{ data, field }">
                <div class="deadline-block" v-if="data[field].days || data[field].hours">
                  <span class="ddln_val" v-if="data[field].days">
                    {{ data[field].days }}
                  </span>
                  <span class="ddln_desc" v-if="data[field].days">days</span> 
                  <br />
                  <span class="ddln_val" v-if="data[field].hours">
                    {{ data[field].hours }}
                  </span>
                  <span class="ddln_desc" v-if="data[field].hours">hours</span>
                </div>
              </template>
            </Column>
            <Column header="">
              <template #body="{ data }">
                <div class="col-dark">
                  <div class="table-col-icon" @click="editLead(data.id)">
                    <EditIcon fill="var(--c_black)" />
                  </div>
                  <div v-if="$store.state.user.is_super_admin">
                    <Tmblr 
                      :active="data.is_manager_can" 
                      customClass="dark"
                      @changeState="toggleProperty(data.id, 'is_manager_can')" />
                  </div>
                  <div class="table-col-icon" @click="addToBalanceItem(data.id)" v-if="$store.state.user.is_super_admin">
                    <Calculator style="width: 25px" fill="var(--c_black)" />
                  </div>
                  <div class="table-col-icon" @click="deleteLead(data.id)">
                    <TableRemoveItemIcon style="width: 30px; height: 30px" />
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
          <pagination
            :data="leadsPaginationData"
            @pagination-change-page="getLeads"
          >
            <span slot="prev-nav">&lt;</span>
            <span slot="next-nav">&gt;</span>
          </pagination>
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
  import { mapState } from "vuex";
  import EditIcon from "@/components/icons/EditIcon.vue";
  import Calculator from "@/components/img/Calculator.vue";
  import TableRemoveItemIcon from "@/components/icons/TableRemoveItemIcon.vue";
  import Tmblr from '@/components/elem/Tmblr';

  export default {
    components: {
      EditIcon,
      TableRemoveItemIcon,
      Tmblr,
      Calculator,
    },
    data: () => ({
      leads: [],
      leadsPaginationData: {},
      loading: false,
      breakedDeadline: false,
      showBreakedDeadline: false,
      search_query: null,
      search_manager: null,
      search_country: null,
      managers: [],
      countries: [],
      show_more: null,
      statuses: [
        {
          name: 'active',
          color: '#00ff00'
        },
        {
          name: 'not active',
          color: 'grey'
        },
        {
          name: 'pushed',
          color: 'orange'
        },
        {
          name: 'lost',
          color: '#ff0000'
        }
      ]
    }),
    async created() {
      this.$store.dispatch('setUser');

      await this.getLeads();
      await this.getManagers()
      await this.getCountries()
    },
    computed: {
      ...mapState({
        user: (state) => state.user,
      }),
    },
    methods: {
      async getCountries () {
        await axios.get('admin/data/countries').then(({data}) => {
          this.countries = data
        }).catch((error) => {
          console.log(error);
        })
      },
      async getManagers () {
        const that = this
        await axios.get('admin/data/managers').then(function (response) {
            that.managers = response.data
          }).catch(function (error) {
            console.log(error);
          })
      },
      async filterLeads(){
        this.showBreakedDeadline = !this.showBreakedDeadline

        await this.getLeads()
      },
      toggleProperty(id, property){
        if(id){
          let urlPart = '';
          
          if(property == 'is_manager_can'){
            urlPart = 'toggle-can';
          }
          
          let that = this
          
          axios.post('admin/leads/' + id + '/' + urlPart)
          .then(function (response) {
            that.updateCurrentPage();
          })
          .catch(function (error) {
            console.log(error);
          })
        }
      },
      async getLeads(page = 1){
        const that = this
        const basePath = 'admin/leads?page=' + page + '&per_page=15'
        const sortParams = '&sort=id&order=desc'

        let filterParams = ''

        filterParams = this.showBreakedDeadline ? '&breaked_deadline=true' : ''
        filterParams += this.search_query ? '&search=' + this.search_query : ''
        filterParams += this.search_manager ? '&manager=' + this.search_manager : ''
        filterParams += this.search_country ? '&country=' + this.search_country : ''

        await axios.get(basePath + sortParams + filterParams).then(function (response) {
            that.breakedDeadline = response.data.breaked_deadline
            that.leadsPaginationData = response.data.data
            that.leads = []

            response.data.data.data.forEach(el => {
              that.leads.push({
                id: el.id,
                customer: el.customer_id + el.customer ? el.customer.name + ' ' + el.customer.email : '',
                client_number: el.client_number,
                customer_name: {type: 'link', link: el.web_site, text: el.client_name ? el.client_name : el.web_site },
                joined_at: el.joined_at,
                status: el.status,
                connect_links: that.getLeadsLinksContent(el.lead_links),
                customer_type: el.customer_type,
                country: el.country ? el.country.name : '',
                last_request:  el.last_request,
                balance: el.balance + ' | ' + el.paid_balance,
                manager_name: el.manager_name,
                agent_name: (el.agent_name ? el.agent_name : '') + (el.agent_fee != null ? (' | ' + el.agent_fee) : ''),
                updated_at: that.getFormattedDate(el.updated_at),
                deadline_at: { ...that.getDeadlinePeriod(el.deadline_at) },
                is_manager_can: el.is_manager_can,
              })
            })
          }).catch((errors) => {
            console.dir(errors)
          })
      },
      showMore(leadId){
        if(this.show_more == leadId){
          this.show_more = null
        }
        else{
          this.show_more = leadId
        }
      },
      getStatusColor(status){
        if(status){
          status = status.toLowerCase().trim()
      
          for(let i = 0; i < this.statuses.length; i++){
            let name = this.statuses[i].name.toLowerCase().trim()
      
            let indexOf = status.indexOf(name)
      
            if(indexOf != -1 && indexOf == 0){
              return this.statuses[i].color
            }
          }
      
          for(let i = 0; i < this.statuses.length; i++){
            let name = this.statuses[i].name.toLowerCase().trim()
            
            if(status.includes(name)){
              return this.statuses[i].color
            }
          }
        }
      
        return ''
      },
      getFormattedDate(value){
        const date = new Date(value);

        const formattedDate = date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        });

        return formattedDate;
      },
      getDeadlinePeriod(date){
        if(date != null){
          const now = new Date();
          const deadlineDate = new Date(date);

          const difference = deadlineDate - now;

          const days = Math.floor(difference / (1000 * 60 * 60 * 24));
          const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

          return { days, hours };
        }
      },
      getLeadsLinksContent(arr){
        let links = []

        arr.forEach((item, i) => {
          links.push({
            link: item.link,
            text: item.service_name ? item.service_name : item.link
          })
        })
        
        return links
      },
      createLead() {
        this.$store.commit('setModal', {
          template: 'lead',
          options: {
            id: null,
            actionType: 'create',
            table: 'lead',
            objectType: 'lead',
            url: 'admin/leads',
            successfullMessage: 'You successfully created Internal Deals',
          }
        })
      },
      editLead(id) {
        this.$store.commit('setModal', {
          template: 'lead',
          options: {
            id: id,
            actionType: 'edit',
            table: 'lead',
            objectType: 'lead',
            url: 'admin/leads/' + id,
            successfullMessage: 'You successfully updated Internal Deals',
          }
        })
      },
      addToBalanceItem(id) {
        this.$store.commit('setModal', {
          template: 'LeadAddToBalance',
          options: {
            id: id,
            actionType: 'add-to-balance',
            table: 'lead',
            objectType: 'lead',
            url: 'admin/leads/' + id,
            successfullMessage: 'You successfully added Internal Deal to balance',
          }
        })
      },
      deleteLead(id) {
        this.$store.commit('setModal', {
          template: 'delete',
          options: {
            id: id,
            actionType: 'delete',
            table: 'lead',
            type: 'lead',
            url: 'admin/leads/' + id,
            successfullMessage: 'You successfully deleted Internal Deals',
          }
        })
      },
      documentTemplatesModal(){
        this.$store.commit('setModal', {
          template: 'document-templates',
          options: {
            type: 'leads'
          }
        })
      },
      importantLinksModal(){
        this.$store.commit('setModal', {
          template: 'important-links',
        })
      },
      async updateCurrentPage() {
        await this.getLeads(1);
      },
      async sortBy(data) {
        this[data.obj].sort.loading = true;
        this[data.obj].sort.order =
          this[data.obj].sort.name === data.sort &&
          this[data.obj].sort.order === "asc"
            ? "desc"
            : "asc";
        this[data.obj].sort.name = data.sort;

        switch (data.obj) {
          case "lead":
            await this.getLeads(1);
            break;
        }
      },
    },
    watch: {
      "$store.state.deleteItemFromTableData": function(){
        this.updateCurrentPage();
      },
      "$store.state.editItemFromTableData": function(){
        this.updateCurrentPage();
      },
      "$store.state.createItemInTable": function(){
        this.updateCurrentPage();
      }
    },
  };
</script>

<style lang="scss" scoped>
.search-input{
  padding: 15px;
  border-radius: 30px;
  background-color: var(--c_black);
  color: #fff;
  border: 1px solid #666;
  width: 200px;
  height: 56px;
}
.top-btns-wrapper{
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  flex: 0 0 100%;
  gap: 12px
}
.box-header__left{
  gap: 12px;
  align-items: center;
}
.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: .25rem;
}
.page-link span {
  color: white;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  border: 1px solid #dee2e6;
  color: white !important;
}
.top-left-actions-btns {
  display: flex;
}
.select-risk {
  margin-left: 1rem;
  padding: 15px 40px;
  border-radius: 30px;
  background-color: var(--c_black);
  color: #fff;
}
.col-dark {
  color: #fff;
  display: flex;
  gap: 1rem;
}
.table-col-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>