<template>
  <overlay-scrollbars class="page page-offer shapes-bg">
    <div class="section-title-wrap">
      <h2 class="page-details__title section-title">
        <span class="bc-first">
          Task Manager
        </span>
      </h2>
    </div>
    <div class="top-btns-wrapper">
      <div @click="createTask" class="btn-gradient create-account-btn">Create</div>
    </div>
    <div class="box box_selectedAcc box-bg-shapes">
      <div class="box-header">
        <div class="box-header__left">
          <div class="box-title">Tasks</div>
          <div>
            <input 
              class="search-input" 
              type="text" 
              placeholder="Type to search" 
              v-model="search_query"
              @change="getTasks"
              @keyup.enter="getTasks">
          </div>
          <div>
            <select name="search_status" class="search-input" v-model="search_status" @change="getTasks">
              <option :value="null">Select Status</option>
              <option 
                v-for="(status, i) in statuses" 
                :value="status"
              >
                {{ status }}
              </option>
            </select>
          </div>
          <div>
            <select name="search_manager" class="search-input" v-model="search_manager" @change="getTasks">
              <option :value="null">Responsible manager</option>
              <option 
                v-for="(manager, i) in managers" 
                :value="manager.name"
              >
                {{ manager.name }}
              </option>
            </select>
          </div>
          <div>
            <select name="search_control_manager" class="search-input" v-model="search_control_manager" @change="getTasks">
              <option :value="null">Control manager</option>
              <option 
                v-for="(manager, i) in managers" 
                :value="manager.name"
              >
                {{ manager.name }}
              </option>
            </select>
          </div>
          <div>
            <Tmblr 
              :active="showBreakedDeadline" 
              :text="'Show breaked deadline  (' + breakedDeadline + ')'" 
              @changeState="filterTasks" />
          </div>
        </div>
      </div>
      <div class="box-body">
        <div class="main-table tasks-table">
          <DataTable :value="tasks" dataKey="id" :loading="loadingTasks">
            <Column field="id" header="#"></Column>
            <Column field="task" header="Task"></Column>
            <Column field="client_name" header="Client name"></Column>
            <Column field="tasked_at" header="Date of task"></Column>
            <Column field="task_description" header="Task description">
              <template #body="{ data, field }">
                <div class="show-more" :class="{'hidden': show_more != data['id']}">
                  {{ data[field] }}
                </div>
                <span class="fake-link" @click="showMore(data['id'])"> 
                  <span v-if="show_more != data['id']">
                    show more
                  </span>
                  <span v-else>
                    show less
                  </span>
                </span>
              </template>
            </Column>
            <Column field="status" header="Status">
              <template #body="{ data, field }">
                <div :style="'color:' + getTaskStatusColor(data[field])">
                  {{ data[field] }}
                </div>
              </template>
            </Column>
            <Column field="links" header="Links">
              <template #body="{ data, field }">
                <div v-for="(item, i) in data[field]">
                  <a :href="item.link">
                    {{ item.text }}
                  </a>
                </div>
              </template>
            </Column>
            <Column field="responsible_manager_name" header="Responsible manager"></Column>
            <Column field="control_manager_name" header="Control manager"></Column>
            <Column field="asystent_name" header="Assistant"></Column>
            <Column field="closed_at" header="Closed at"></Column>
            <Column field="deadline_at" header="Deadline at">
              <template #body="{ data, field }">
                <div class="deadline-block" v-if="data[field].days || data[field].hours">
                  <span class="ddln_val" v-if="data[field].days">
                    {{ data[field].days }}
                  </span>
                  <span class="ddln_desc" v-if="data[field].days">days</span> 
                  <br />
                  <span class="ddln_val" v-if="data[field].hours">
                    {{ data[field].hours }}
                  </span>
                  <span class="ddln_desc" v-if="data[field].hours">hours</span>
                </div>
              </template>
            </Column>
            <Column header="">
              <template #body="{ data }">
                <div class="col-dark">
                  <div class="table-col-icon" @click="editTask(data.id)">
                    <EditIcon fill="var(--c_black)" />
                  </div>
                  <div class="table-col-icon" @click="showComments(data.id)" style="width: 24px">
                    <Chat fill="var(--c_black)" />
                  </div>
                  <div v-if="$store.state.user.is_super_admin">
                    <Tmblr 
                      :active="data.is_manager_can" 
                      customClass="dark"
                      @changeState="toggleTaskProperty(data.id, 'is_manager_can')" />
                  </div>
                  <div class="table-col-icon" @click="deleteTask(data.id)" v-if="$store.state.user.is_super_admin">
                    <TableRemoveItemIcon style="width: 30px; height: 30px" />
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
          <pagination
            :data="tasksPaginationData"
            @pagination-change-page="getTasks"
          >
            <span slot="prev-nav">&lt;</span>
            <span slot="next-nav">&gt;</span>
          </pagination>
        </div>
      </div>
    </div>
    <br />
    <div class="top-btns-wrapper">
      <div @click="createProcess" class="btn-gradient create-account-btn">Create</div>
    </div>
    <div class="box box_selectedAcc box-bg-shapes">
      <div class="box-header">
        <div class="box-header__left">
          <div class="box-title">Processes</div>
          <div>
            <input 
              class="search-input" 
              type="text" 
              placeholder="Type to search" 
              v-model="process_search_query"
              @change="getProcesses"
              @keyup.enter="getProcesses">
          </div>

          <div>
            <select name="search_manager" class="search-input" v-model="process_search_manager" @change="getProcesses">
              <option :value="null">Manager</option>
              <option 
                v-for="(manager, i) in managers" 
                :value="manager.name"
              >
                {{ manager.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="box-body">
        <div class="main-table processes-table">
          <DataTable :value="processes" dataKey="id" :loading="loadingProcesses">
            <Column field="id" header="#"></Column>
            <Column field="process" header="Process"></Column>
            <Column field="manager_name" header="Manager"></Column>
            <Column field="links" header="Links">
              <template #body="{ data, field }">
                <div v-for="(item, i) in data[field]">
                  <a :href="item.link">
                    {{ item.text }}
                  </a>
                </div>
              </template>
            </Column>
            
            <Column field="" header="Steps">
              <template #body="{ data }">
                <div class="col-dark">
                  <div class="table-col-icon" @click="showProcessSteps(data.id)">
                    <Info style="width: 24px; max-width: 24px" fill="var(--c_black)" />
                  </div>
                </div>
              </template>
            </Column>
            <Column header="">
              <template #body="{ data }">
                <div class="col-dark">
                  <div class="table-col-icon" v-if="$store.state.user.is_super_admin" @click="editProcess(data.id)">
                    <EditIcon fill="var(--c_black)" />
                  </div>
                  <div v-if="$store.state.user.is_super_admin">
                    <Tmblr 
                      :active="data.is_manager_can" 
                      customClass="dark"
                      @changeState="toggleProcessProperty(data.id, 'is_manager_can')" />
                  </div>
                  <div class="table-col-icon" @click="deleteProcess(data.id)" v-if="$store.state.user.is_super_admin">
                    <TableRemoveItemIcon style="width: 30px; height: 30px" />
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
          <pagination
            :data="processesPaginationData"
            @pagination-change-page="getProcesses"
          >
            <span slot="prev-nav">&lt;</span>
            <span slot="next-nav">&gt;</span>
          </pagination>
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
  import { mapState } from "vuex";
  import EditIcon from "@/components/icons/EditIcon.vue";
  import Chat from "@/components/img/Chat.vue";
  import Info from "@/components/img/Info.vue";
  import TableRemoveItemIcon from "@/components/icons/TableRemoveItemIcon.vue";
  import Tmblr from '@/components/elem/Tmblr';

  export default {
    components: {
      EditIcon,
      TableRemoveItemIcon,
      Tmblr,
      Chat,
      Info
    },
    data: () => ({
      tasks: [],
      tasksPaginationData: {},
      loadingTasks: false,
      breakedDeadline: false,
      showBreakedDeadline: false,
      search_query: null,
      search_status: null,
      search_manager: null,
      search_control_manager: null,
      processes: [],
      processesPaginationData: {},
      loadingProcesses: false,
      process_search_query: null,
      process_search_manager: null,
      managers: [],
      countries: [],
      show_more: null,
      statuses: [
        'Active',
        'In Progress',
        'On Hold',
        'Close Win',
        'Close Lost'
      ],
    }),
    async created() {
      this.$store.dispatch('setUser');

      await this.getTasks()
      await this.getProcesses()
      await this.getManagers()
    },
    computed: {
      ...mapState({
        user: (state) => state.user,
      }),
    },
    methods: {
      async getManagers () {
        const that = this
        await axios.get('admin/data/managers').then(function (response) {
            that.managers = response.data
          }).catch(function (error) {
            console.log(error);
          })
      },
      async filterTasks(){
        this.showBreakedDeadline = !this.showBreakedDeadline

        await this.getTasks()
      },
      async getTasks(page = 1){
        const that = this
        const basePath = 'admin/tasks?page=' + page + '&per_page=15'
        const sortParams = '&sort=id&order=desc'

        let filterParams = ''

        filterParams = this.showBreakedDeadline ? '&breaked_deadline=true' : ''
        
        filterParams += this.search_query ? '&search=' + this.search_query : ''
        
        filterParams += this.search_status ? '&status=' + this.search_status : ''
        
        filterParams += this.search_manager ? '&manager=' + this.search_manager : ''
        
        filterParams += this.search_control_manager ? '&control_manager=' + this.search_control_manager : ''

        await axios.get(basePath + sortParams + filterParams).then(function (response) {
            that.breakedDeadline = response.data.breaked_deadline
            that.tasksPaginationData = response.data.data
            that.tasks = []

            response.data.data.data.forEach(el => {
              that.tasks.push({
                id: el.id,
                task: el.task,
                client_name: el.client_name,
                tasked_at: that.getFormattedDate(el.tasked_at),
                task_description: el.task_description,
                status: el.status,
                links: that.getLinksContent(el.task_links),
                responsible_manager_name: el.responsible_manager_name,
                control_manager_name: el.control_manager_name,
                asystent_name: el.asystent_name,
                closed_at: that.getFormattedDate(el.closed_at),
                deadline_at: { ...that.getDeadlinePeriod(el.deadline_at) },
                is_manager_can: el.is_manager_can
              })
            })
          }).catch((errors) => {
            console.dir(errors)
          })
      },
      async getProcesses(page = 1){
        const that = this
        const basePath = 'admin/processes?page=' + page + '&per_page=15'
        const sortParams = '&sort=id&order=desc'
      
        let filterParams = ''
      
        filterParams += this.process_search_query ? '&search=' + this.process_search_query : ''
        
        filterParams += this.process_search_manager ? '&manager=' + this.process_search_manager : ''
      
        await axios.get(basePath + sortParams + filterParams).then(function (response) {
            that.processesPaginationData = response.data.data
            that.processes = []
      
            response.data.data.data.forEach(el => {
              that.processes.push({
                id: el.id,
                process: el.process,
                manager_name: el.manager_name,
                links: that.getLinksContent(el.process_links),
                is_manager_can: el.is_manager_can
              })
            })
          }).catch((errors) => {
            console.dir(errors)
          })
      },
      showMore(taskId){
        if(this.show_more == taskId){
          this.show_more = null
        }
        else{
          this.show_more = taskId
        }
      },
      getStatusColor(status){
        if(status){
          status = status.toLowerCase().trim()
      
          for(let i = 0; i < this.statuses.length; i++){
            let name = this.statuses[i].name.toLowerCase().trim()
      
            let indexOf = status.indexOf(name)
      
            if(indexOf != -1 && indexOf == 0){
              return this.statuses[i].color
            }
          }
      
          for(let i = 0; i < this.statuses.length; i++){
            let name = this.statuses[i].name.toLowerCase().trim()
            
            if(status.includes(name)){
              return this.statuses[i].color
            }
          }
        }
      
        return ''
      },
      getFormattedDate(value){
        if(value){
          const date = new Date(value);
          
          const formattedDate = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          });
          
          return formattedDate;
        }
      },
      getDeadlinePeriod(date){
        if(date != null){
          const now = new Date();
          const deadlineDate = new Date(date);

          const difference = deadlineDate - now;

          const days = Math.floor(difference / (1000 * 60 * 60 * 24));
          const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

          return { days, hours };
        }
      },
      getTaskStatusColor(status) {
        switch (status) {
          case 'Active':
            return '#007bff';
          case 'In Progress':
            return '#fd7e14';
          case 'On Hold':
            return '#6c757d';
          case 'Close Win':
            return '#28a745';
          case 'Close Lost':
            return '#dc3545'
          default:
            return '#ffffff';
        }
      },
      getLinksContent(arr){
        let links = []

        arr.forEach((item, i) => {
          links.push({
            link: item.link,
            text: item.service_name ? item.service_name : item.link
          })
        })
        
        return links
      },
      createTask() {
        this.$store.commit('setModal', {
          template: 'task',
          options: {
            id: null,
            actionType: 'create',
            table: 'task',
            objectType: 'task',
            url: 'admin/tasks',
            successfullMessage: 'You successfully created Task',
          }
        })
      },
      editTask(id) {
        this.$store.commit('setModal', {
          template: 'task',
          options: {
            id: id,
            actionType: 'edit',
            table: 'task',
            objectType: 'task',
            url: 'admin/tasks/' + id,
            successfullMessage: 'You successfully updated Task',
          }
        })
      },
      showComments(id){
        this.$store.commit('setModal', {
          template: 'task-comments',
          options: {
            id: id,
            actionType: 'show-comments',
            table: 'tasks',
            objectType: 'task',
          }
        })
      },
      deleteTask(id) {
        this.$store.commit('setModal', {
          template: 'delete',
          options: {
            id: id,
            actionType: 'delete',
            table: 'task',
            type: 'task',
            url: 'admin/tasks/' + id,
            successfullMessage: 'You successfully deleted Task',
          }
        })
      },
      createProcess() {
        this.$store.commit('setModal', {
          template: 'process',
          options: {
            id: null,
            actionType: 'create',
            table: 'process',
            objectType: 'process',
            url: 'admin/processes',
            successfullMessage: 'You successfully created Process',
          }
        })
      },
      editProcess(id) {
        this.$store.commit('setModal', {
          template: 'process',
          options: {
            id: id,
            actionType: 'edit',
            table: 'process',
            objectType: 'process',
            url: 'admin/processes/' + id,
            successfullMessage: 'You successfully updated Process',
          }
        })
      },
      showProcessSteps(id){
        this.$store.commit('setModal', {
          template: 'process-steps',
          options: {
            id: id,
            actionType: 'show-steps',
            table: 'process',
            objectType: 'process'
          }
        })
      },
      deleteProcess(id) {
        this.$store.commit('setModal', {
          template: 'delete',
          options: {
            id: id,
            actionType: 'delete',
            table: 'process',
            type: 'process',
            url: 'admin/processes/' + id,
            successfullMessage: 'You successfully deleted Process',
          }
        })
      },
      async updateCurrentPage() {
        let taskPage = this.tasksPaginationData.current_page
        let processPage = this.processesPaginationData.current_page
        
        await this.getTasks(taskPage);
        await this.getProcesses(processPage);
      },
      async sortBy(data) {
        this[data.obj].sort.loading = true;
        this[data.obj].sort.order =
          this[data.obj].sort.name === data.sort &&
          this[data.obj].sort.order === "asc"
            ? "desc"
            : "asc";
        this[data.obj].sort.name = data.sort;

        switch (data.obj) {
          case "task":
            await this.getTasks(1);
            break;
          case "process":
            await this.getProcesses(1);
            break;
        }
      },
      toggleTaskProperty(id, property){
        if(id){
          let urlPart = '';
          
          if(property == 'is_manager_can'){
            urlPart = 'toggle-can';
          }
          
          let that = this
          
          axios.post('admin/tasks/' + id + '/' + urlPart)
          .then(function (response) {
            that.updateCurrentPage();
          })
          .catch(function (error) {
            console.log(error);
          })
        }
      },
      toggleProcessProperty(id, property){
        if(id){
          let urlPart = '';
          
          if(property == 'is_manager_can'){
            urlPart = 'toggle-can';
          }
          
          let that = this
          
          axios.post('admin/processes/' + id + '/' + urlPart)
          .then(function (response) {
            that.updateCurrentPage();
          })
          .catch(function (error) {
            console.log(error);
          })
        }
      },
    },
    watch: {
      "$store.state.deleteItemFromTableData": function(){
        this.updateCurrentPage();
      },
      "$store.state.editItemFromTableData": function(){
        this.updateCurrentPage();
      },
      "$store.state.createItemInTable": function(){
        this.updateCurrentPage();
      }
    },
  };
</script>

<style lang="scss" scoped>
.search-input{
  padding: 15px;
  border-radius: 30px;
  background-color: var(--c_black);
  color: #fff;
  border: 1px solid #666;
  width: 200px;
  height: 56px;
}
.top-btns-wrapper{
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  flex: 0 0 100%;
  gap: 12px
}
.box-header__left{
  gap: 12px;
  align-items: center;
}
.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: .25rem;
}
.page-link span {
  color: white;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  border: 1px solid #dee2e6;
  color: white !important;
}
.top-left-actions-btns {
  display: flex;
}
.select-risk {
  margin-left: 1rem;
  padding: 15px 40px;
  border-radius: 30px;
  background-color: var(--c_black);
  color: #fff;
}
.col-dark {
  color: #fff;
  display: flex;
  gap: 1rem;
}
.table-col-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>