<template>
  <overlay-scrollbars class="page page-offer shapes-bg">
    <div class="section-title-wrap">
      <h2 class="page-details__title section-title">
        <span class="bc-first">Account Requests</span>
      </h2>
    </div>
    <div class="top-btns-wrapper">
      <div
        @click="createAccountRequest"
        class="btn-gradient create-account-btn"
      >
        Create
      </div>
      <div
        @click="documentTemplatesModal"
        class="btn-gradient create-account-btn"
      >
        Document templates
      </div>
      <div @click="workFlowModal" class="btn-gradient create-account-btn">
        Work flow
      </div>
    </div>
    <div class="box box_selectedAcc box-bg-shapes">
      <div class="box-header">
        <div class="box-header__left">
          <div class="box-title">Account Requests</div>
          <div>
            <input 
              class="search-input" 
              type="text" 
              placeholder="Type to search" 
              v-model="search_query"
              @change="getAccountRequests"
              @keyup.enter="getAccountRequests">
          </div>
          <div>
            <select name="search_country" class="search-input" v-model="search_country" @change="getAccountRequests">
              <option :value="null">Select country</option>
              <option 
                v-for="(country, i) in countries" 
                :value="country.id"
                :key="i"
              >
                {{ country.name }}
              </option>
            </select>
          </div>
          <div>
            <select name="search_manager" class="search-input" v-model="search_manager" @change="getAccountRequests">
              <option :value="null">Select manager</option>
              <option 
                v-for="(manager, i) in managers" 
                :value="manager.name"
                :key="i"
              >
                {{ manager.name }}
              </option>
            </select>
          </div>
          <div>
            <Tmblr 
              :active="showBreakedDeadline" 
              :text="'Show breaked deadline  (' + breakedDeadline + ')'" 
              @changeState="filterAccountRequests" />
          </div>
        </div>
      </div>
      <div class="box-body">
        <div class="main-table offers-table">
          <DataTable :value="accountRequests" dataKey="id" :loading="loading">
            <Column field="id" header="ID"></Column>
            <Column field="country" header="Country"></Column>
            <Column field="license_type" header="License type"></Column>
            <Column field="account_type" header="Account type"></Column>
            <Column field="account_system" header="Account system"></Column>
            <Column field="bank_name" header="Bank name"></Column>
            <Column field="bank_country" header="Bank country"></Column>
            <Column field="bank_license" header="Bank license"></Column>
            <Column field="bank_pay_in" header="Bank pay-in"></Column>
            <Column field="bank_pay_out" header="Bank pay-out"></Column>
            <Column field="bank_fee" header="Bank fee"></Column>
            <Column field="our_fee" header="Our fee"></Column>
            <Column field="deadline_at" header="Deadline at">
              <template #body="{ data, field }">
                <div class="deadline-block" v-if="data[field].days || data[field].hours">
                  <span class="ddln_val" v-if="data[field].days">
                    {{ data[field].days }}
                  </span>
                  <span class="ddln_desc" v-if="data[field].days">days</span> 
                  <br />
                  <span class="ddln_val" v-if="data[field].hours">
                    {{ data[field].hours }}
                  </span>
                  <span class="ddln_desc" v-if="data[field].hours">hours</span>
                </div>
              </template>
            </Column>
            <Column header="">
              <template #body="{ data }">
                <div class="col-dark">
                  <div
                    class="table-col-icon"
                    @click="editAccountRequest(data.id)"
                  >
                    <EditIcon fill="var(--c_black)" />
                  </div>
                  <div v-if="$store.state.user.is_super_admin">
                    <Tmblr 
                      :active="data.is_manager_can" 
                      customClass="dark"
                      @changeState="toggleProperty(data.id, 'is_manager_can')" />
                  </div>
                  <div
                    class="table-col-icon"
                    @click="deleteAccountRequest(data.id)"
                  >
                    <TableRemoveItemIcon style="width: 30px; height: 30px" />
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
          <pagination
            :data="accountRequestsPaginationData"
            @pagination-change-page="getAccountRequests"
          >
            <span slot="prev-nav">&lt;</span>
            <span slot="next-nav">&gt;</span>
          </pagination>
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>
  
  <script>
import { mapState } from "vuex";
import EditIcon from "@/components/icons/EditIcon.vue";
import TableRemoveItemIcon from "@/components/icons/TableRemoveItemIcon.vue";
import Tmblr from '@/components/elem/Tmblr';

export default {
  components: {
    EditIcon,
    TableRemoveItemIcon,
    Tmblr,
  },
  data: () => ({
    accountRequests: [],
    accountRequestsPaginationData: {},
    loading: false,
    breakedDeadline: false,
    showBreakedDeadline: false,
    search_query: null,
    search_manager: null,
    search_country: null,
    managers: [],
    countries: [],
    show_more: null,
  }),
  async created() {
    this.$store.dispatch("setUser");

    await this.getAccountRequests();
    await this.getManagers();
    await this.getCountries();
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
    async getCountries() {
      await axios
        .get("admin/data/countries")
        .then(({ data }) => {
          this.countries = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getManagers() {
      const that = this;
      await axios
        .get("admin/data/managers")
        .then(function (response) {
          that.managers = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    filterAccountRequests() {
      this.showBreakedDeadline = !this.showBreakedDeadline;

      this.getAccountRequests();
    },
    
    toggleProperty(id, property){
      if(id){
        let urlPart = '';
        
        if(property == 'is_manager_can'){
          urlPart = 'toggle-can';
        }
        
        let that = this
        
        axios.post('admin/account-requests/' + id + '/' + urlPart)
        .then(function (response) {
          that.updateCurrentPage();
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    async getAccountRequests(page = 1) {
      const that = this;
      const basePath = "admin/account-requests?page=" + page + "&per_page=15";
      const sortParams = "&sort=id&order=desc";

      let filterParams = "";

      filterParams = this.showBreakedDeadline ? "&breaked_deadline=true" : "";
      filterParams += this.search_query ? "&search=" + this.search_query : "";
      filterParams += this.search_manager
        ? "&manager=" + this.search_manager
        : "";
      filterParams += this.search_country
        ? "&country=" + this.search_country
        : "";

      await axios.get(basePath + sortParams + filterParams).then(function (response) {
          that.breakedDeadline = response.data.breaked_deadline;
          that.accountRequestsPaginationData = response.data.data;
          that.accountRequests = [];

          response.data.data.data.forEach((el) => {
            that.accountRequests.push({
              id: el.id,
              country: el.country_id ? el.country.name : '',
              license_type: el.license_type,
              account_type: el.account_type,
              account_system: el.account_system,
              bank_name: el.bank_name,
              bank_country: el.bank_country_id ? el.bank_country.name : '',
              bank_license: el.bank_license,
              bank_pay_in: el.bank_pay_in,
              bank_pay_out: el.bank_pay_out,
              bank_fee: el.bank_fee,
              our_fee: el.our_fee,
              deadline_at: { ...that.getDeadlinePeriod(el.deadline_at) },
              is_manager_can: el.is_manager_can
            });
          });
        }).catch((errors) => {
          console.dir(errors);
        });
    },
    showMore(accountRequstId) {
      if (this.show_more == accountRequstId) {
        this.show_more = null;
      } else {
        this.show_more = accountRequstId;
      }
    },
    getFormattedDate(value) {
      const date = new Date(value);

      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });

      return formattedDate;
    },
    getDeadlinePeriod(date) {
      if (date != null) {
        const now = new Date();
        const deadlineDate = new Date(date);

        const difference = deadlineDate - now;

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );

        return { days, hours };
      }
    },
    createAccountRequest() {
      this.$store.commit("setModal", {
        template: "account-request",
        options: {
          id: null,
          actionType: "create",
          table: "account-requests",
          objectType: "account-requests",
          url: "admin/account-requests",
          successfullMessage: "You successfully created Account Request",
        },
      });
    },
    editAccountRequest(id) {
      this.$store.commit("setModal", {
        template: "account-request",
        options: {
          id: id,
          actionType: "edit",
          table: "account-requests",
          objectType: "account-requests",
          url: "admin/account-requests/" + id,
          successfullMessage: "You successfully updated Account Request",
        },
      });
    },
    deleteAccountRequest(id) {
      this.$store.commit("setModal", {
        template: "delete",
        options: {
          id: id,
          actionType: "delete",
          table: "account-requests",
          type: "account-requests",
          url: "admin/account-requests/" + id,
          successfullMessage: "You successfully deleted Account Request",
        },
      });
    },
    documentTemplatesModal() {
      this.$store.commit("setModal", {
        template: "document-templates",
        options: {
          type: "account_requests",
        },
      });
    },
    workFlowModal() {
      this.$store.commit("setModal", {
        template: "work-flow",
      });
    },
    updateCurrentPage() {
      this.getAccountRequests(1);
    },
    sortBy(data) {
      this[data.obj].sort.loading = true;
      this[data.obj].sort.order =
        this[data.obj].sort.name === data.sort &&
        this[data.obj].sort.order === "asc"
          ? "desc"
          : "asc";
      this[data.obj].sort.name = data.sort;

      switch (data.obj) {
        case "account-request":
          this.getAccountRequests(1);
          break;
      }
    },
  },
  watch: {
    "$store.state.deleteItemFromTableData": function () {
      this.updateCurrentPage();
    },
    "$store.state.editItemFromTableData": function () {
      this.updateCurrentPage();
    },
    "$store.state.createItemInTable": function () {
      this.updateCurrentPage();
    },
  },
};
</script>
  
<style lang="scss" scoped>
.search-input{
  padding: 15px;
  border-radius: 30px;
  background-color: var(--c_black);
  color: #fff;
  border: 1px solid #666;
  width: 200px;
  height: 56px;
}
.top-btns-wrapper{
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  flex: 0 0 100%;
  gap: 12px
}
.box-header__left{
  gap: 12px;
  align-items: center;
}
.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.page-link span {
  color: white;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  border: 1px solid #dee2e6;
  color: white !important;
}
.top-left-actions-btns {
  display: flex;
}
.select-risk {
  margin-left: 1rem;
  padding: 15px 40px;
  border-radius: 30px;
  background-color: var(--c_black);
  color: #fff;
}
.col-dark {
  color: #fff;
  display: flex;
  gap: 1rem;
}
.table-col-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>